<script setup>
import {defineEmits, defineExpose, defineProps, onMounted, ref} from 'vue'

// Props & emits
const emit = defineEmits(['changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const defaultFormData = {
  service_url: '',
  app_url: '',
  preland_url: '',
  test_login: '',
  test_password: '',
  plan_to_spend: '',
}

let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}
const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <label for="dating-wl-ad-account-name">Link to the Dating Service *</label>
    <div class="input-group">
      <input
        v-model="formData.service_url"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="dating-wl-ad-account-name"
        required
        type="url"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="dating-wl-ad-account-name">Do you have an app? (if yes, please provide a link)</label>
    <div class="input-group">
      <input
        v-model="formData.app_url"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="dating-wl-ad-account-name"
        type="text"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="dating-wl-preland-link">Will you use a preland? (if yes, please provide a link)</label>
    <div class="input-group">
      <input
        v-model="formData.preland_url"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="dating-wl-preland-link"
        type="text"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="dating-wl-app-login">Test login for this site or app *</label>
    <div class="input-group">
      <input
        v-model="formData.test_login"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="dating-wl-app-login"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="dating-wl-app-password">Test password for this site or app *</label>
    <div class="input-group">
      <input
        v-model="formData.test_password"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="dating-wl-app-password"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="dating-wl-spend">How much do you plan to spend during your first 3 months on Facebook? *</label>
    <div class="input-group">
      <span class="input-group-text">$</span>
      <input
        v-model="formData.plan_to_spend"
        @change="onChange"
        :disabled="disabled"
        class="form-control"
        id="dating-wl-spend"
        required
      />
    </div>
  </div>
</template>

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useAdAccountWhiteListRequestStore = defineStore({
  id: 'adAccountWhiteListRequestStore',
  state: () => ({
    countList: {},
  }),
  actions: {
    async getAdAccountWhiteListRequests(filters = {}) {
      const {data} = await axios.get('/whitelist-requests', {params: filters})

      return data
    },

    async createAdAccountWhiteListRequest(filePayload = null, filters = {}) {
      const {data} = await axios.post('/whitelist-requests', filePayload, {params: filters})

      return data
    },
  },
})

import {createRouter, createWebHistory} from 'vue-router'
import AdminRoutes from './admin_routes.js'
import {beforeEach as AdminBeforeEach} from './admin_routes.js'
import LoginRoutes from './login_routes.js'
import {authenticationStore, pointBoardStore} from '@/main.js'
import {setPageTitle} from '@/plugins/helpers'

const routes = [
  {
    path: '/',
    name: 'index',

    beforeEnter: async (to, form, next) => {
      setPageTitle(to)

      const token = localStorage.getItem('user-token')

      if (token) {
        await pointBoardStore.setFullPagePreloader(true)

        await authenticationStore.loadAuthUserInfo()
        await pointBoardStore.setFullPagePreloader(false)
        next()
      } else {
        next()
      }
    },

    children: [AdminRoutes, LoginRoutes],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => AdminBeforeEach(to, from, next))

export default router

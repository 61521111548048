<template>
  <div class="preloader">
    <div class="preloader__inner">
      <div class="preloader__box">
        <img src="@/assets/images/preloader_p2w.webp" alt="point2web" class="preloader__box__icon" />
        <p class="preloader__box__text animated_text-dots">Loading</p>
      </div>
    </div>
  </div>
</template>

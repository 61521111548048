import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useSharePermissionsRequestStore = defineStore({
  id: 'sharePermissionsRequest',
  state: () => ({
    error: null,
  }),
  actions: {
    async getSharePermissionsRequests(params = {}) {
      const {data} = await axios.get('share-requests', {params})

      return data
    },
    async createSharePermissionsRequest(payload) {
      const {data} = await axios.post('share-requests', payload)

      return data
    },
    async updateSharePermissionsRequest(hash, payload) {
      const {data} = await axios.patch(`share-requests/${hash}`, payload)

      return data
    },
    async changeStatus(hash, payload) {
      const {data} = await axios.patch(`share-requests/${hash}`, payload)

      return data
    },
  },
})

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useMetaBusinessManagerStore = defineStore({
  id: 'businessManagerStore',
  state: () => ({
    facebookBusinessManagers: [],
    facebookBusinessManagersLoading: false,
    error: null,
  }),
  getters: {},
  actions: {
    async getFacebookBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.facebookBusinessManagersLoading = true
      try {
        const {data} = await axios.get('facebook/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.facebookBusinessManagers = data.data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.facebookBusinessManagersLoading = false
      }
    },

    async createFacebookBusinessManager(payload) {
      this.error = null
      this.facebookBusinessManagersLoading = true

      try {
        const {data} = await axios.post('facebook/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.facebookBusinessManagersLoading = false
      }
    },

    async updateFacebookBusinessManager(id, payload) {
      this.error = null
      this.facebookBusinessManagersLoading = true

      try {
        const {data} = await axios.patch(`facebook/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.facebookBusinessManagersLoading = false
      }
    },

    async updateFacebookBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`facebook/business-managers/${hash}/providers`, payload)

      return data
    },

    resetFacebookBusinessManagers() {
      this.facebookBusinessManagers = []
    },
  },
})

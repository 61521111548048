<template>
  <div class="auth-layout">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  components: {},

  setup() {
    return {}
  },
})
</script>

<script setup>
import {defineExpose, defineProps, onMounted, ref} from 'vue'
import {Modal as BaseModal} from 'bootstrap'

// Props & emits
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
const modalId = props.id ?? 'account-request-platform-modal'

// Refs
const modal = ref()
const publicPath = process.env.BASE_URL

// Methods
onMounted(() => {
  let modalElement = document.getElementById(modalId)
  modal.value = new BaseModal(modalElement)
})

const openModal = () => {
  modal.value.show()
}

const closeModal = () => {
  modal.value.hide()
}

defineExpose({openModal, closeModal})
</script>

<template>
  <div class="modal modal-lg fade" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Choose platform</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal" />
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'meta-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/meta-logo.svg`" alt="meta" class="max-w-20 me-1" />
                  <span>Meta</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'tiktok-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/tiktok-logo.svg`" alt="meta" class="max-w-20 me-1" />
                  <span>Tiktok</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'google-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/google-logo.svg`" alt="google" class="max-w-20 me-1" />
                  <span>Google</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'bigo-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/bigo-logo.svg`" alt="bigo" class="max-w-20 me-1" />
                  <span>Bigo</span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="row mb-3">
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'bing-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/bing-logo.svg`" alt="bing" class="max-w-20 me-1" />
                  <span>Bing</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'snapchat-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/snapchat-logo.svg`" alt="snapchat" class="max-w-20 me-1" />
                  <span>Snapchat</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'taboola-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/taboola-logo.svg`" alt="taboola" class="max-w-20 me-1" />
                  <span>Taboola</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'outbrain-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img :src="`${publicPath}images/platforms/outbrain-logo.svg`" alt="outbrain" class="max-w-20 me-1" />
                  <span>Outbrain</span>
                </div>
              </div>
            </router-link>
          </div>
          <div class="row justify-content-center">
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'newsbreak-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img
                    :src="`${publicPath}images/platforms/newsbreak-logo.svg`"
                    alt="newsbreak"
                    class="max-w-20 me-1"
                  />
                  <span>Newsbreak</span>
                </div>
              </div>
            </router-link>
            <router-link class="col-3 no-underline" @click="closeModal" :to="{name: 'revcontent-ad-account-request'}">
              <div class="card text-center">
                <div class="card-body">
                  <img
                    :src="`${publicPath}images/platforms/revcontent-logo.svg`"
                    alt="revcontent"
                    class="max-w-20 me-1"
                  />
                  <span>Revcontent</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

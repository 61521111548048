import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useTaboolaBusinessManagerStore = defineStore({
  id: 'taboolaBusinessManagerStore',
  state: () => ({
    taboolaBusinessManagers: [],
    taboolaBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getTaboolaBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.taboolaBusinessManagersLoading = true

      try {
        const {data} = await axios.get('taboola/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.taboolaBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.taboolaBusinessManagersLoading = false
      }
    },

    async createTaboolaBusinessManager(payload) {
      this.error = null
      this.taboolaBusinessManagersLoading = true

      try {
        const {data} = await axios.post('taboola/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.taboolaBusinessManagersLoading = false
      }
    },

    async updateTaboolaBusinessManager(id, payload) {
      this.error = null
      this.taboolaBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`taboola/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.taboolaBusinessManagersLoading = false
      }
    },

    async updateTaboolaBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`taboola/business-managers/${hash}/providers`, payload)

      return data
    },

    async getTaboolaProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('taboola/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})

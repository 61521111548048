import AuthLayout from '../layouts/AuthLayout.vue'
import {authenticationStore} from '@/main'

export default {
  path: '/',
  name: 'authorization',
  component: AuthLayout,
  redirect: {
    name: 'auth.login',
  },
  children: [
    {
      path: '/login',
      name: 'auth.login',
      component: () => import('@/views/AuthView.vue'),
      beforeEnter: (to, from, next) => {
        authenticationStore.isAuthenticated ? next({name: 'dashboard'}) : next()
      },
      meta: {
        title: 'Sign in',
      },
    },
    {
      path: '/reset-password',
      name: 'auth.reset-password',
      component: () => import('@/views/auth/PasswordResetForm.vue'),
      beforeEnter: (to, from, next) => {
        authenticationStore.isAuthenticated ? next({name: 'dashboard'}) : next()
      },
      meta: {
        title: 'Sign in',
      },
    },
  ],
}

<script setup>
import {defineProps} from 'vue'

const props = defineProps({
  showPreloader: {
    default: false,
    type: Boolean,
  },
  name: {
    default: '',
    type: String,
  },
})
</script>

<template>
  <template v-if="props.showPreloader">
    <div class="input-preloader" v-bind="$attrs">
      <span class="animated_text-dots">Loading {{ props.name }}</span>
    </div>
  </template>
  <template v-else>
    <slot></slot>
  </template>
</template>

<style lang="scss">
.input-preloader__loading {
  &:after {
    content: ' .';
    animation: dots 1.5s steps(5, end) infinite;
  }
}
</style>

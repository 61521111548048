import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useOutbrainBusinessManagerStore = defineStore({
  id: 'outbrainBusinessManagerStore',
  state: () => ({
    outbrainBusinessManagers: [],
    outbrainBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getOutbrainBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.outbrainBusinessManagersLoading = true

      try {
        const {data} = await axios.get('outbrain/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.outbrainBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.outbrainBusinessManagersLoading = false
      }
    },

    async createOutbrainBusinessManager(payload) {
      this.error = null
      this.outbrainBusinessManagersLoading = true

      try {
        const {data} = await axios.post('outbrain/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.outbrainBusinessManagersLoading = false
      }
    },

    async updateOutbrainBusinessManager(id, payload) {
      this.error = null
      this.outbrainBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`outbrain/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.outbrainBusinessManagersLoading = false
      }
    },

    async updateOutbrainBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`outbrain/business-managers/${hash}/providers`, payload)

      return data
    },

    async getOutbrainProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('outbrain/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})

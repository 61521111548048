import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useNewAdAccountRequestStore = defineStore({
  id: 'newAdAccountRequest',
  state: () => ({
    countList: {},
  }),
  actions: {
    async getAdAccountRequests(filters = {}) {
      const {data} = await axios.get('ad-account-requests', {
        params: filters,
      })

      return data
    },

    async getAdAccountRequestsCounts(filters = {}) {
      const {data} = await axios.get('ad-account-requests/counts', {
        params: {...filters},
      })

      this.countList = data

      return data
    },

    async getAdAccountRequestHistory(payload) {
      const {data} = await axios.get(`ad-account-requests/history`, {params: payload})

      return data
    },

    async addInternalComment(payload) {
      const {data} = await axios.post('ad-account-requests/internal-comment', payload)

      return data
    },

    async changeResponsible(payload) {
      const {data} = await axios.post('ad-account-requests/change-responsible', payload)

      return data
    },

    async changeCompletedBy(payload) {
      const {data} = await axios.post('ad-account-requests/change-completed-by', payload)

      return data
    },

    async getAdAccounts(params) {
      const {data} = await axios.get('ad-accounts', {params})

      return data
    },
  },
})

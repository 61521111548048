<script setup>
import {defineProps, defineModel, computed} from 'vue'
import Multiselect from '@vueform/multiselect'

const modelValue = defineModel({required: true})
const props = defineProps({
  id: {
    type: String,
    default: 'the-select-multiple',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'tags',
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  allow_clear: {
    type: Boolean,
    default: true,
  },
  can_deselect: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
  },
})

const selectMode = computed(() => (props.mode === 'tags' ? 'tags' : 'multiple'))
const isValid = computed(() => !props.required || !!modelValue.value)
</script>

<template>
  <div :class="['the-select-wrapper', selectMode === 'tags' ? 'tags' : '', isValid ? 'valid' : 'invalid']">
    <input v-if="required && !isValid" class="d-none" required />
    <Multiselect
      v-model="modelValue"
      :options="props.options"
      :id="id"
      :mode="selectMode"
      :disabled="props.disabled"
      :placeholder="placeholder"
      :searchable="searchable"
      :canDeselect="can_deselect"
      :required="required"
    >
      <template v-if="!allow_clear" #clear><span /></template>
    </Multiselect>
  </div>
</template>

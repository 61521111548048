<script setup>
import {watch, ref} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const title = ref('')

watch(
  () => route?.meta?.title,
  () => {
    title.value = route?.meta?.title
  },
  {immediate: true},
)
</script>

<template>
  <span>{{ title }}</span>
</template>

<style lang="scss" scoped></style>

<script setup>
import {usePointBoardStore} from '@/stores/PointBoardStore'
import {storeToRefs} from 'pinia'
import ThePreloader from '@/components/ThePreloader.vue'

const {fullPagePreloader} = storeToRefs(usePointBoardStore())
</script>

<template>
  <notifications />
  <transition name="fade">
    <the-preloader v-show="fullPagePreloader"></the-preloader>
  </transition>
  <router-view />
</template>

<style lang="scss">
@import '@/assets/styles/vendor';
@import '@/assets/styles/style.scss';

.full-page-preloader {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: 9999;
}
</style>

<script setup>
import {useAuthenticationStore} from '@/stores'
import {useUserStore} from '@/stores/UserStore'
import {storeToRefs} from 'pinia'
import TheSelect from '@/components/ui/TheSelect.vue'
import {computed, ref} from 'vue'
import {getValidationError, isProduction} from '@/plugins/helpers'
import {useNotification} from '@kyvg/vue3-notification'

const {notify} = useNotification()
const {impersonate} = useAuthenticationStore()
const {getImpersonationEmployees} = useUserStore()
const {user} = storeToRefs(useAuthenticationStore())

const users = ref([])
const usersOptions = computed(() => users.value?.map(({user_id, label}) => ({value: user_id, label: label})))

const init = async () => {
  try {
    users.value = await getImpersonationEmployees()
  } catch (error) {
    //
  }
}

const handleChangeUser = async id => {
  try {
    await impersonate(id)
  } catch (error) {
    return notify({type: 'error', text: getValidationError(error.data.errors)})
  }

  isProduction() ? window.open(location.href) : location.reload()
}

init()
</script>

<template>
  <div class="w-240-px me-3">
    <the-select
      :options="usersOptions"
      :model-value="user.id"
      @change="handleChangeUser"
      :allow_clear="false"
      :can_deselect="false"
    />
  </div>
</template>

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useAdAccountTopUpRequestStore = defineStore({
  id: 'adAccountTopUpRequest',
  actions: {
    async createAdAccountTopUpRequest(payload) {
      const {data} = await axios.post('ad-account-top-up-requests', {ad_accounts: payload})

      return data
    },

    async updateAdAccountTopUpRequest(payload) {
      const {data} = await axios.patch(`ad-account-top-up-requests/${payload.id}`, payload)

      return data
    },

    async updateAdAccountTopUpSumRequest(payload) {
      const {data} = await axios.post(`top-up-request-update-amount/${payload.id}`, payload)

      return data
    },

    async getAdAccountTopUpRequests(params = {}) {
      const {data} = await axios.get('ad-account-top-up-requests', {params})

      return data
    },

    async getAdAccountTopUpRequestSummary(params = {}) {
      const {data} = await axios.get('/ad-account-top-up-requests/summary', {params})

      return data
    },
    async deleteAdAccountTopUpRequest(id) {
      return await axios.delete(`ad-account-top-up-requests/${id}`)
    },

    async addToTecDo(id) {
      const {data} = await axios.post(`facebook/tech-do/top-up-request/${id}`)

      return data
    },
  },
})

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useBingBusinessManagerStore = defineStore({
  id: 'bingBusinessManagerStore',
  state: () => ({
    bingBusinessManagers: [],
    bingBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getBingBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.bingBusinessManagersLoading = true

      try {
        const {data} = await axios.get('bing/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.bingBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.bingBusinessManagersLoading = false
      }
    },

    async createBingBusinessManager(payload) {
      this.error = null
      this.bingBusinessManagersLoading = true

      try {
        const {data} = await axios.post('bing/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.bingBusinessManagersLoading = false
      }
    },

    async updateBingBusinessManager(id, payload) {
      this.error = null
      this.bingBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`bing/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.bingBusinessManagersLoading = false
      }
    },

    async updateBingBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`bing/business-managers/${hash}/providers`, payload)

      return data
    },

    async getBingProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('bing/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})

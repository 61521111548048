<script setup></script>

<template>
  <div class="d-flex p-5 align-items-center">
    <img src="/404.webp" class="image" alt="404" />
    <div class="ps-5">
      <h3>Lost in the Point2Web universe?</h3>
      <p class="mb-5">Let's get you our of this 404 situation.</p>

      <b>What do you want to do?</b>

      <div class="d-flex mt-2">
        <a href="/">Return to the Home Page</a>
        <div class="px-3">|</div>
        <a href="#" onclick="history.back();return false;">Go Back</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image {
  width: 300px;
}
</style>

export function debounce(fn, delay = 300) {
  let timer

  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }

    const context = this
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

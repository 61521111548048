import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useRevcontentBusinessManagerStore = defineStore({
  id: 'revcontentBusinessManagerStore',
  state: () => ({
    revcontentBusinessManagers: [],
    revcontentBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getRevcontentBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.revcontentBusinessManagersLoading = true

      try {
        const {data} = await axios.get('revcontent/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.revcontentBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.revcontentBusinessManagersLoading = false
      }
    },

    async createRevcontentBusinessManager(payload) {
      this.error = null
      this.revcontentBusinessManagersLoading = true

      try {
        const {data} = await axios.post('revcontent/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.revcontentBusinessManagersLoading = false
      }
    },

    async updateRevcontentBusinessManager(id, payload) {
      this.error = null
      this.revcontentBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`revcontent/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.revcontentBusinessManagersLoading = false
      }
    },

    async updateRevcontentBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`revcontent/business-managers/${hash}/providers`, payload)

      return data
    },

    async getRevcontentProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('revcontent/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})

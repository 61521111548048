<script>
export default {
  props: {
    form_id: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    was_validated: false,
  }),
  methods: {
    submitForm() {
      if (this.isValidForm()) {
        this.$emit('on-submitted')
      } else {
        this.$emit('on-validation-failed')
      }
    },
    isValidForm() {
      this.was_validated = true

      if (!this.$refs.form.checkValidity()) {
        return false
      }

      this.was_validated = false

      return true
    },
    reset() {
      this.was_validated = false
    },
  },
}
</script>

<template>
  <form
    @submit.prevent="submitForm"
    :class="['needs-validation form m-form', was_validated ? 'was-validated' : '']"
    :id="form_id"
    ref="form"
    novalidate="true"
  >
    <slot></slot>
  </form>
</template>

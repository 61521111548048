<script setup>
import {defineProps} from 'vue'

const props = defineProps({
  lgBorderRadius: {
    type: Boolean,
    required: false,
  },
})
</script>

<template>
  <div :class="['card border-0', props.lgBorderRadius ? 'border-radius-lg' : '']">
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useBigoBusinessManagerStore = defineStore({
  id: 'bigoBusinessManagerStore',
  state: () => ({
    bigoBusinessManagers: [],
    bigoBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getBigoBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.bigoBusinessManagersLoading = true

      try {
        const {data} = await axios.get('bigo/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.bigoBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.bigoBusinessManagersLoading = false
      }
    },

    async createBigoBusinessManager(payload) {
      this.error = null
      this.bigoBusinessManagersLoading = true

      try {
        const {data} = await axios.post('bigo/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.bigoBusinessManagersLoading = false
      }
    },

    async updateBigoBusinessManager(id, payload) {
      this.error = null
      this.bigoBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`bigo/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.bigoBusinessManagersLoading = false
      }
    },

    async updateBigoBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`bigo/business-managers/${hash}/providers`, payload)

      return data
    },

    async getBigoProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('bigo/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useAdAccountRenameRequestStore = defineStore({
  id: 'adAccountRenameRequest',
  actions: {
    async createAdAccountRenameRequest(payload) {
      const {data} = await axios.post('ad-account-rename-requests', payload)

      return data
    },

    async updateAdAccountRenameRequest(payload) {
      const {data} = await axios.patch(`ad-account-rename-requests/${payload.id}`, payload)

      return data
    },

    async deleteAdAccountRenameRequest(id) {
      const {data} = await axios.delete(`ad-account-rename-requests/${id}`)

      return data
    },

    async reassignBuyer(id, payload) {
      const {data} = await axios.post(`rename-requests/${id}/reassign-buyer`, payload)

      return data
    },

    async getAdAccountRenameRequests(params = {}) {
      const {data} = await axios.get('ad-account-rename-requests', {params})

      return data
    },
  },
})

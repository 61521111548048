import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useGoogleBusinessManagerStore = defineStore({
  id: 'googleBusinessManagerStore',
  state: () => ({
    googleBusinessManagers: [],
    googleBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getGoogleBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.googleBusinessManagersLoading = true

      try {
        const {data} = await axios.get('google/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.googleBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.googleBusinessManagersLoading = false
      }
    },

    async createGoogleBusinessManager(payload) {
      this.error = null
      this.googleBusinessManagersLoading = true

      try {
        const {data} = await axios.post('google/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.googleBusinessManagersLoading = false
      }
    },

    async updateGoogleBusinessManager(id, payload) {
      this.error = null
      this.googleBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`google/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.googleBusinessManagersLoading = false
      }
    },

    async updateGoogleBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`google/business-managers/${hash}/providers`, payload)

      return data
    },

    async getGoogleProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('google/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})

import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useLandingsStore = defineStore({
  id: 'landingsStore',
  state: () => ({
    units: [],
    landings: [],
    landingsLoading: false,
  }),
  getters: {
    unitOptions: state => state.units.map(({id, name}) => ({value: id, label: name})),
  },
  actions: {
    async getUnits(params = {}) {
      const {data} = await axios.get('units', {params})

      this.units = data

      return data
    },

    async getLandings(filters = {}) {
      const {data} = await axios.get('/landings', {
        params: filters,
      })

      return data
    },

    async updateLanding(id, payload) {
      const {data} = await axios.patch(`/landings/${id}`, payload)

      return data
    },

    async deleteLanding(id) {
      await axios.delete(`/landings/${id}`)
    },
  },
})

import axios from 'axios'
import {authenticationStore, mainRouter} from '@/main'
import Swal from 'sweetalert2'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://127.0.0.1:8000',
})

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('user-token')

    config.headers.Authorization = `Bearer ${token}`

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const {status} = error.request

    if (status === 401) {
      await authenticationStore.logout()

      await mainRouter.push({
        name: 'auth.login',
      })
    }

    if (status === 403) {
      Swal.fire({title: error?.response?.data?.message ?? 'Forbidden', icon: 'error'})
    }

    return Promise.reject(error.response)
  },
)

export default axiosInstance

<script setup>
import WhiteListModal from '@/views/ad-account-requests/white-lists/components/WhiteListModal.vue'
import ShareRequestModal from '@/views/ad-account-requests/share-permissions/components/ShareRequestModal.vue'
import AdAccountTopUpRequestModal from '@/views/ad-account-requests/top-up/AdAccountTopUpRequestModal.vue'
import AdAccountRenameRequestModal from '@/views/ad-account-requests/rename/AdAccountRenameRequestModal.vue'
import {ref} from 'vue'
import AdAccountRequestsPlatformsModal from '@/views/ad-account-requests/components/AdAccountRequestsPlatformsModal.vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const showWhiteListModal = ref(false)
const topUpAdAccountRequestModal = ref()
const renameRequestModal = ref()
const showSharePermissionModal = ref(false)
const platformsModal = ref()

// Methods
const openTopUpAdAccountRequestModal = () => {
  topUpAdAccountRequestModal.value.openModal()
}
const openRenameRequestModal = () => {
  renameRequestModal.value.openModal()
}
const openShareRequestModal = () => {
  showSharePermissionModal.value = true
}
const openWhitelistRequestModal = () => {
  showWhiteListModal.value = true
}
const openAccountRequestPlatformsModal = () => {
  platformsModal.value.openModal()
}
const onShareRequestCreated = () => {
  router.push({name: 'share-permissions'})
}
const onRenameRequestCreated = () => {
  router.push({name: 'ad-account-requests.rename.list'})
}
const onTopupRequestCreated = () => {
  router.push({name: 'ad-account-requests.top-up.list'})
}
const onWhiteRequestCreated = () => {
  router.push({name: 'white-list'})
}
</script>

<template>
  <ad-account-top-up-request-modal
    ref="topUpAdAccountRequestModal"
    id="dropdown-ad-account-top-up-request-modal"
    @created="onTopupRequestCreated"
  />
  <ad-account-rename-request-modal
    ref="renameRequestModal"
    id="dropdown-ad-account-rename-request-modal"
    @created="onRenameRequestCreated"
  />
  <share-request-modal
    v-if="showSharePermissionModal"
    @hidden="showSharePermissionModal = false"
    id="dropdown-share-request-modal"
    @created="onShareRequestCreated"
  />
  <ad-account-requests-platforms-modal ref="platformsModal" id="dropdown-requests-platforms-modal" />

  <white-list-modal
    v-if="showWhiteListModal"
    ref="whiteListRequestModal"
    id="dropdown-requests-white-list-modal"
    @hidden="showWhiteListModal = false"
    @created="onWhiteRequestCreated"
  />
  <div class="btn-group custom" role="group" v-bind="$attrs">
    <button type="button" class="btn btn-primary dropdown-toggle">Create Request</button>

    <ul class="dropdown-menu">
      <li>
        <span @click.prevent="openAccountRequestPlatformsModal" class="dropdown-item">New Ad Account</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openTopUpAdAccountRequestModal">New Top Up</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openRenameRequestModal">Account Rename</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openShareRequestModal">Account Share</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openWhitelistRequestModal">White lists</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.custom {
  & .dropdown-toggle {
    font-weight: 700;
  }

  & .dropdown-menu {
    width: 100%;
    min-width: 100%;
    padding: 2px 0;
    border: 1px solid #eb704b;
  }

  & .dropdown-item {
    cursor: pointer;
    padding: 6px 10px;
    border-bottom: 1px solid #d9d9d9;
  }

  & li:last-of-type .dropdown-item {
    border-bottom: 0 !important;
  }
}
</style>

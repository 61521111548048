import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useTiktokBusinessManagerStore = defineStore({
  id: 'tiktokBusinessManagerStore',
  state: () => ({
    tiktokBusinessManagers: [],
    tiktokBusinessManagersLoading: false,
    error: null,
  }),
  actions: {
    async getTiktokBusinessManagers(filters = {}, withRelation = undefined) {
      this.error = null
      this.tiktokBusinessManagersLoading = true

      try {
        const {data} = await axios.get('tiktok/business-managers', {
          params: {...filters, with: withRelation},
        })

        this.tiktokBusinessManagers = data

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.tiktokBusinessManagersLoading = false
      }
    },

    async createTiktokBusinessManager(payload) {
      this.error = null
      this.tiktokBusinessManagersLoading = true

      try {
        const {data} = await axios.post('tiktok/business-managers', payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.tiktokBusinessManagersLoading = false
      }
    },

    async updateTiktokBusinessManager(id, payload) {
      this.error = null
      this.tiktokBusinessManagersLoading = true

      try {
        let {data} = await axios.patch(`tiktok/business-managers/${id}`, payload)

        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.tiktokBusinessManagersLoading = false
      }
    },

    async updateTiktokBusinessManagerProviders(hash, payload) {
      const {data} = await axios.patch(`tiktok/business-managers/${hash}/providers`, payload)

      return data
    },

    async getTiktokProviders(filters = {}) {
      this.error = null
      this.loading = true

      try {
        const {data} = await axios.get('tiktok/providers', {params: filters})
        return data
      } catch (responseError) {
        this.error = responseError.data
      } finally {
        this.loading = false
      }
    },
  },
})
